import React from "react"
import Page from '../components/Page'
import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import StoryblokService from '../utils/storyblok-service'
import { Helmet } from 'react-helmet'
 
export default class PageTemplate extends React.Component {
  state = {
    story: {
       content: this.props.data.story ? JSON.parse(this.props.data.story.content) : {}
    }
  }
 
  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.data.story.full_slug}`)
    return story
  }
 
  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }
 
  render() {
    return (
       <Layout location={this.props.location}>
        <Helmet htmlAttributes={{ lang: 'en', }}>
          <title>{this.state.story.content.meta_title ? this.state.story.content.meta_title :this.state.story.name}</title>
          <meta property="og:title" content={this.state.story.content.meta_title ? this.state.story.content.meta_title :this.state.story.name} />
          <meta name="description" content={this.state.story.content.meta_description ? this.state.story.content.meta_description : 'Leeds based child care for children 3 months to 5 years.'} />
          <meta property="og:description" content={this.state.story.content.meta_description ? this.state.story.content.meta_description : 'Leeds based child care for children 3 months to 5 years.'} />
          {this.state.story.content.meta_image?.filename && <meta property="og:image" content={this.state.story.content.meta_image.filename} />}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={this.state.story.content.meta_title ? this.state.story.content.meta_title :this.state.story.name} />
          <meta name="twitter:description" content={this.state.story.content.meta_description ? this.state.story.content.meta_description : 'Leeds based child care for children 3 months to 5 years.'} />
          {this.state.story.content.meta_image?.filename && <meta name="twitter:image" content={this.state.story.content.meta_image.filename} />}
          <link rel="icon" type="/images/favicon.ico" href="/images/favicon.ico" sizes="16x16" />
        </Helmet>
        <Page blok={this.state.story.content} />
      </Layout>
    )
  }
}

 
export const query = graphql`
  query storyBySlug($fullSlug: String!) {
    story: storyblokEntry(full_slug: { eq: $fullSlug }) {
      name
      content
      full_slug
      uuid
    }
  }
`